type UseSwiperArgs = {
  handleSwipeLeft?: () => void;
  handleSwipeRight?: () => void;
  handleSwipeUp?: () => void;
  handleSwipeDown?: () => void;
};

type HandleGestureArgs = {
  touchStartX: number;
  touchStartY: number;
  touchEndX: number;
  touchEndY: number;
};

export const useSwiper = (
  elementID: string,
  { handleSwipeLeft, handleSwipeRight, handleSwipeUp, handleSwipeDown }: UseSwiperArgs
) => {
  const touchStartXState = useState<number | null>('touchStartXState', () => null)
  const touchEndXState = useState<number | null>('touchEndXState', () => null)
  const touchStartYState = useState<number | null>('touchStartYState', () => null)
  const touchEndYState = useState<number | null>('touchEndYState', () => null)

  onMounted(() => {
    const element = document.getElementById(elementID)

    if (element) {
      element.addEventListener(
        'touchstart',
        (event) => {
          const { screenX, screenY } = event.changedTouches[0]
          touchStartXState.value = (screenX)
          touchStartYState.value = (screenY)
        },
        false
      )

      element.addEventListener(
        'touchend',
        (event) => {
          const { screenX, screenY } = event.changedTouches[0]
          touchEndXState.value = (screenX)
          touchEndYState.value = (screenY)
          handleGesture({
            touchStartX: touchStartXState.value as number,
            touchStartY: touchStartYState.value as number,
            touchEndX: touchEndXState.value as number,
            touchEndY: touchEndYState.value as number,
          })
        },
        false
      )
    }
  })

  const handleGesture = ({ touchStartX, touchEndY, touchEndX, touchStartY }: HandleGestureArgs) => {
    const DELAY_PX = 20

    const [isSwipeLeft, isSwipeRight, isSwipeUp, isSwipeDown] = [
      touchEndX + DELAY_PX < touchStartX,
      touchEndX > touchStartX + DELAY_PX,
      touchEndY + DELAY_PX < touchStartY,
      touchEndY > touchStartY + DELAY_PX,
    ]

    if (isSwipeLeft && handleSwipeLeft) {
      handleSwipeLeft()
    }

    if (isSwipeRight && handleSwipeRight) {
      handleSwipeRight()
    }

    if (isSwipeUp && handleSwipeUp) {
      handleSwipeUp()
    }

    if (isSwipeDown && handleSwipeDown) {
      handleSwipeDown()
    }
  }
}
