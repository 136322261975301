import { getCurrentLanguage } from "../lang-config"
import { addLastSlashToURL } from "./string"

interface Meta {
  id?: string;
  name?: string;
  property?: string;
  content: string;
}

interface Link {
  rel: string;
  href: string;
  title?: string;
}

interface MetaObject {
  title: string;
  meta: Meta[];
  link: Link[];
  htmlAttrs: { lang: string };
}

export default class HeadMetaBuilder {
  private data: MetaObject
  constructor() {
    this.data = {
      title: '',
      meta: [],
      link: [],
      htmlAttrs: {
        lang: getCurrentLanguage(),
      },
    }
  }

  get(): object {
    return this.data
  }

  static create() {
    return new HeadMetaBuilder()
  }

  withTitle(title: string) {
    this.data.title = title

    return this
  }

  withMetaTitle(title: string) {
    this.data.meta.push({
      name: 'title',
      content: title,
    })

    return this
  }

  withTitleAndMetaTitle(title: string) {
    this.withTitle(title).withMetaTitle(title)

    return this
  }

  withMetaDescription(description: string) {
    this.data.meta.push({
      name: 'description',
      content: description,
    })

    return this
  }

  withLink(rel: string, href: string, title?: string) {
    const link: Link = {
      rel,
      href: addLastSlashToURL(href)
    }

    if (title) {
      link.title = title
    }

    this.data.link.push(link)

    return this
  }

  withTwitterMeta(title: string, description: string) {
    this.data.meta.push(
      ...[
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
      ]
    )

    return this
  }

  withTwitterImage(imageUrl: string) {
    this.data.meta.push({
      name: 'twitter:image',
      content: imageUrl,
    })

    this.data.meta.push({
      name: 'twitter:image:src',
      content: imageUrl,
    })

    return this
  }

  withOpenGraphMeta(title: string, description: string) {
    this.data.meta.push(
      ...[
        {
          property: 'og:locale',
          content: 'es_ES',
        },
        {
          property: 'og:site_name',
          content: 'Spalopia.com',
        },
        {
          property: 'og:type',
          content: 'article',
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
      ]
    )

    return this
  }

  withOpenGraphImage(imageUrl: string) {
    this.data.meta.push(
      ...[
        {
          property: 'og:image',
          content: imageUrl,
        },
        {
          property: 'og:image:secure_url',
          content: imageUrl,
        },
      ]
    )

    return this
  }

  withThumbnailImage(imageUrl: string) {
    this.data.meta.push({
      name: 'thumbnail',
      content: imageUrl,
    })

    return this
  }
}
